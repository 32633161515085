/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef, useState} from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {useFrame} from "@react-three/fiber";
import {Vector3} from "three";

type GLTFResult = GLTF & {
  nodes: {
    Object_10: THREE.SkinnedMesh
    Object_11: THREE.SkinnedMesh
    Object_9: THREE.SkinnedMesh
    Object_93: THREE.SkinnedMesh
    _rootJoint: THREE.Bone
  }
  materials: {
    material: THREE.MeshStandardMaterial
    material_2: THREE.MeshStandardMaterial
    Spider: THREE.MeshStandardMaterial
  }
}

type ActionName = 'spider_armature|dead2' | 'spider_armature|jump' | "spider_armature|spider's dead" | 'spider_armature|walkcyclefaster'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export default function Spider({ playerPosition }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/spider.glb`;
  // scale={0.5} rotation={[0,0.5,0]}
  const scale = 3.5;
  let position = new Vector3();
  position.x = 0;
  position.y = 0;
  position.z = 0;

  const SPEED = 3;
  const group = useRef<THREE.Group>(null!)

  useFrame(() => {
    // if (typeof playerPosition !== "undefined") {
    //   // group.current.position.x = playerPosition.x - 0.8;
    //   // group.current.position.z = playerPosition.z - 0.8;
    //
    //   const dx = playerPosition.x - group.current.position.x;
    //   const dz = playerPosition.z - group.current.position.z;
    //   const dist = Math.sqrt(dx * dx + dz * dz);
    //   if (dist > 0) {
    //     group.current.position.x += dx / dist * SPEED;
    //     group.current.position.z += dz / dist * SPEED;
    //   }
    // }
  })

  function rotateNPC() {
    const dx = playerPosition.x - group.current.position.x;
    const dz = playerPosition.z - group.current.position.z;
    const angle = Math.atan2(dz, dx);
    group.current.rotation.y = 1.5 - angle;
    console.log(`dx: ${dx}, dz: ${dz}, angle: ${angle}`);
  }

  window.setInterval(() => {
    if (typeof playerPosition !== "undefined") {
      // group.current.position.x = playerPosition.x - 0.8;
      // group.current.position.z = playerPosition.z - 0.8;

      rotateNPC();

      const dx = playerPosition.x - group.current.position.x;
      const dz = playerPosition.z - group.current.position.z;
      const dist = Math.sqrt(dx * dx + dz * dz);
      if (dist > 0) {
        group.current.position.x += dx / dist * SPEED;
        group.current.position.z += dz / dist * SPEED;
      }
    }
  }, 500);

  // @ts-ignore
  const { nodes, materials, animations } = useGLTF(ITEM_URI) as GLTFResult
  // @ts-ignore
  const { actions } = useAnimations<GLTFActions>(animations, group)

  // @ts-ignore
  const [mixer] = useState(() => new THREE.AnimationMixer())

  useEffect(() => {
    mixer.clipAction(animations[3], group.current).setDuration(2.5);
    mixer.clipAction(animations[3], group.current).play();
  }, [animations])

  useFrame((scene, delta) => {
    mixer?.update(delta)
  });

  return (
    <group  castShadow ref={group} dispose={null} position={position} scale={scale}>
      <group  castShadow name="Scene">
        <group  castShadow name="Spider" scale={5.72}>
          <group  castShadow name="fur" rotation={[-Math.PI / 2, 0, 0]} />
          <group  castShadow name="spider" rotation={[-Math.PI / 2, 0, 0]} />
          <group  castShadow name="spider_armature" scale={0.05}>
            <group  castShadow name="Object_6">
              <primitive  castShadow object={nodes._rootJoint} />
              <group  castShadow name="Object_8" rotation={[-Math.PI / 2, 0, 0]} />
              <group castShadow  name="Object_92" rotation={[-Math.PI / 2, 0, 0]} />
              <skinnedMesh castShadow name="Object_10" geometry={nodes.Object_10.geometry} material={materials.material} skeleton={nodes.Object_10.skeleton} />
              <skinnedMesh castShadow name="Object_11" geometry={nodes.Object_11.geometry} material={materials.material_2} skeleton={nodes.Object_11.skeleton} />
              <skinnedMesh castShadow name="Object_9" geometry={nodes.Object_9.geometry} material={materials.Spider} skeleton={nodes.Object_9.skeleton} />
              <skinnedMesh castShadow name="Object_93" geometry={nodes.Object_93.geometry} material={materials.material} skeleton={nodes.Object_93.skeleton} />
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}
