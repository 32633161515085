import * as THREE from "three"
import { CuboidCollider, RigidBody } from "@react-three/rapier"
import {useLoader} from "@react-three/fiber";

export function GroundBase({texture}: any) {

  texture.wrapS = texture.wrapT = THREE.RepeatWrapping

  return (
    <RigidBody type="fixed" colliders={false}>
      <mesh receiveShadow position={[0, -0.5, 0]} rotation-x={-Math.PI / 2}>
        <planeGeometry args={[1000, 1000]} />
        <meshStandardMaterial attach="material" side={THREE.DoubleSide} map={texture} map-repeat={[240, 240]} color="white" />
      </mesh>
      <CuboidCollider args={[1000, 2, 1000]} position={[0, -2, 0]} />
    </RigidBody>
  )
}

export function Ground() {
  let img = {
    grass: `${process.env.REACT_APP_ASSETS_URL}/dirt.jpg`
  };
  const texture = useLoader(THREE.TextureLoader, img.grass);

  return (
    <GroundBase texture={texture} />
  )
}
