import * as THREE from "three"
import * as RAPIER from "@dimforge/rapier3d-compat"
import {useEffect, useRef, useState} from "react"
import React from "react";
import { useThree, useFrame } from "@react-three/fiber"
import { useKeyboardControls } from "@react-three/drei"
import { CapsuleCollider, RigidBody, useRapier } from "@react-three/rapier"
import CarriedRose from "./RegularVersion/CarriedRose";
import {checkObjectPlacement} from "../../helpers/helperFunctions";
import CarriedRocket from "./RocketMode/CarriedRocket";
// import Axe from "./Axe"
import nipplejs from "nipplejs";

let SPEED = 7; // todo change to 6 or 7
let JUMP_HEIGHT = 7.5;
// let JUMP_HEIGHT = 200; // for high level view
let PLAYER_START_POSITION = [2,1,14];
// let PLAYER_START_POSITION = [-0.05239903926849365, 1.2482167482376099, 4.106739044189453];
// let PLAYER_START_POSITION = [-40.63,26.39,-39.73]; // for debugging (top of invisible stairs)

const direction = new THREE.Vector3()
const frontVector = new THREE.Vector3()
const sideVector = new THREE.Vector3()
const rotation = new THREE.Vector3()

// var options = {
//   zone: Element,                  // active zone
//   color: String,
//   size: Integer,
//   threshold: Float,               // before triggering a directional event
//   fadeTime: Integer,              // transition time
//   multitouch: Boolean,
//   maxNumberOfNipples: Number,     // when multitouch, what is too many?
//   dataOnly: Boolean,              // no dom element whatsoever
//   position: Object,               // preset position for 'static' mode
//   mode: String,                   // 'dynamic', 'static' or 'semi'
//   restJoystick: Boolean|Object,   // Re-center joystick on rest state
//   restOpacity: Number,            // opacity when not 'dynamic' and rested
//   lockX: Boolean,                 // only move on the X axis
//   lockY: Boolean,                 // only move on the Y axis
//   catchDistance: Number,          // distance to recycle previous joystick in
//                                   // 'semi' mode
//   shape: String,                  // 'circle' or 'square'
//   dynamicPage: Boolean,           // Enable if the page has dynamically visible elements
//   follow: Boolean,                // Makes the joystick follow the thumbstick
// };

/**
 * Player
 *
 * @param placing
 * @param setPlacing
 * @param mode
 * @param DEBUG_MODE
 * @param position
 * @param allDone
 * @param setShowCreditsModal
 * @param objectData
 * @param searchedObject
 * @param lockKeyboard
 * @param hintMessage
 * @param setHintMessage
 * @param setSlideModalCharacter
 * @param setSlideMessage
 * @param setShowSlideModal
 * @param playerPosition
 * @param setPlayerPosition
 * @param pointerControls
 * @param lerp
 * @param setHasObject
 * @param hasObject
 * @param setPlacedObject
 * @param joystickRef
 * @constructor
 */
export function Player(
  { placing, setPlacing, mode, DEBUG_MODE, position = PLAYER_START_POSITION, allDone,
    setShowCreditsModal, objectData, searchedObject, lockKeyboard,
    hintMessage, setHintMessage, setSlideModalCharacter, setSlideMessage, setShowSlideModal,
    playerPosition, setPlayerPosition, pointerControls, lerp = THREE.MathUtils.lerp,
    setHasObject, hasObject, setPlacedObject, joystickRef
  }: any)
{
  const [playerLeft, setPlayerLeft] = useState(0);
  const [playerRight, setPlayerRight] = useState(0);
  const [playerForward, setPlayerForward] = useState(0);
  const [playerBackward, setPlayerBackward] = useState(0);

  const object = useRef(null)
  const ref = useRef(null)
  const rapier = useRapier()
  const { camera } = useThree()
  const [, get] = useKeyboardControls()

  // @ts-ignore
  function handleRotation() { object.current.children[0].rotation.x = -0.5;}

  let joyManager: any;



  useEffect(() => {
    console.log(joystickRef.current)

    let zone = document.getElementById("joystickWrapper1")

    const NIPPLEJS_OPTIONS = {
      // zone: document.getElementById("joystickWrapper1"),
      zone: zone,
      size: 120,
      multitouch: true,
      maxNumberOfNipples: 2,
      mode: "static",
      restJoystick: true,
      shape: "circle",
      position: { top: "60px", left: "60px" },
      dynamicPage: true,
    };
// mobile controls
// @ts-ignore
    joyManager = nipplejs.create(NIPPLEJS_OPTIONS);



    // @ts-ignore
    joyManager['0'].on('move', function (evt, data) {
      const forward = data.vector.y
      const turn = data.vector.x

      console.log(forward)
      console.log(turn)

      if (forward > 0) {
        // fwdValue = Math.abs(forward)
        // bkdValue = 0
        setPlayerForward(1);
        setPlayerBackward(0);
      } else if (forward < 0) {
        // fwdValue = 0
        // bkdValue = Math.abs(forward)
        setPlayerForward(0);
        setPlayerBackward(1);
      }

      if (turn > 0) {
        // lftValue = 0
        // rgtValue = Math.abs(turn)
        setPlayerLeft(0);
        setPlayerRight(1);
      } else if (turn < 0) {
        // lftValue = Math.abs(turn)
        // rgtValue = 0
        setPlayerLeft(1);
        setPlayerRight(0);
      }
    })

    // @ts-ignore
    joyManager['0'].on('end', function (evt) {
      setPlayerForward(0);
      setPlayerBackward(0);
      setPlayerLeft(0);
      setPlayerRight(0);
    })


  }, [])









  /**
   *
   */
  useFrame((state) => {
    const { forward, backward, left, right, jump, shift, control, action, place, info, debug } = get()
    // @ts-ignore
    const velocity = ref.current.linvel()

    if (control && !lockKeyboard) {
      // update camera
      // @ts-ignore
      let translation = ref.current.translation();
      // @ts-ignore
      camera.position.set(translation.x,translation.y = translation.y-0.5, translation.z)
    } else {
      // @ts-ignore
      camera.position.set(...ref.current.translation())
    }








    if (forward) {
      setPlayerForward(1)
    } else {
      setPlayerForward(0)
    }
    if (backward) {
      setPlayerBackward(1)
    } else {
      setPlayerBackward(0)
    }
    if (left) {
      setPlayerLeft(1)
    } else {
      setPlayerLeft(0)
    }
    if (right) {
      setPlayerRight(1)
    } else {
      setPlayerRight(0)
    }



    // update object
    // if (hasObject && !searchedObject) {
    //   // @ts-ignore
    //   object.current.children[0].rotation.x = lerp(object.current.children[0].rotation.x, Math.sin((velocity.length() > 1) * state.clock.elapsedTime * 10) / 6, 0.1)
    //   // @ts-ignore
    //   object.current.rotation.copy(camera.rotation)
    //   // @ts-ignore
    //   object.current.position.copy(camera.position).add(camera.getWorldDirection(rotation).multiplyScalar(1))
    // }

    // movement - backward, forward, left, right, are booleans which need converting to numbers
    frontVector.set(0, 0, playerBackward - playerForward)
    sideVector.set(playerLeft - playerRight, 0, 0)

    // todo something seems to break if press option and keys when running (remove tab indexes from everything in navbar (indluding title)

    if (shift && !lockKeyboard) { // todo might need to add a isRunning state
      direction.subVectors(frontVector, sideVector).normalize().multiplyScalar(SPEED+5).applyEuler(camera.rotation)
    } else if (control) { // todo might need to add a isDucking state
      direction.subVectors(frontVector, sideVector).normalize().multiplyScalar(SPEED-4).applyEuler(camera.rotation)
    } else if (debug && !lockKeyboard) {
      if (DEBUG_MODE) {
        SPEED = 25;
        JUMP_HEIGHT = 150; // 90 to hit clouds, 150 to land on moon
      }
    } else if (info && !lockKeyboard) {
      setTimeout(() => {pointerControls.current.unlock()},100);
      setShowCreditsModal(true);
    } else if (place && !placing && !lockKeyboard && (searchedObject == "" || typeof searchedObject === 'undefined')) {
      if (allDone) return; // don't let player place objects after all done

      setHintMessage('step-3')

      setPlacing(true);
      // console.log('placing')
      // @ts-ignore
      let locationClone = Object.assign({}, ref.current.translation());
      // console.log(locationClone)
      locationClone.x =Math.round((locationClone.x + Number.EPSILON) * 100) / 100;
      locationClone.y = 0;
      locationClone.z =Math.round((locationClone.z + Number.EPSILON) * 100) / 100;

      if (hasObject && !searchedObject) {

        if ((typeof playerPosition !== "undefined") &&
          // this leaves the 'corridor' free
          (playerPosition.z < 0 ) && ((playerPosition.x < 0) || (playerPosition.x > 6))
        ) {
          if (!checkObjectPlacement(objectData, locationClone)) {
            setPlacedObject(locationClone);

            if(hintMessage === 'step-2') {
              setHintMessage('step-3');
            }

            setTimeout(() => {
              // console.log('setting has object to false:', hasObject)
              setHasObject(false)
            }, 100);
          }
        } else {
          setTimeout(() => {pointerControls.current.unlock()},100);
          setSlideModalCharacter('herbert');
          setSlideMessage('outside-fence');
          setShowSlideModal(true);
        }
        setTimeout(() => {
          setPlacing(false);
        }, 500);
      } else {
        // console.log(hasObject)
        setTimeout(() => {
          setHasObject(true);
          setPlacedObject(false);
          setPlacing(false);
        }, 500);
      }
    } else if ((forward || backward || left || right) && !lockKeyboard) {  // todo this might be being called over and might not be needed (without it, the player keeps running
      direction.subVectors(frontVector, sideVector).normalize().multiplyScalar(SPEED).applyEuler(camera.rotation)
      // @ts-ignore
      setPlayerPosition(ref.current.translation());
      if (DEBUG_MODE) {
        console.log('Player Position:', playerPosition);
      }
    } else {
      if (!lockKeyboard) {
        // todo this might be being called over and might not be needed (without it, the player keeps running
        direction.subVectors(frontVector, sideVector).normalize().multiplyScalar(SPEED).applyEuler(camera.rotation)
      }
    }
    // @ts-ignore
    ref.current.setLinvel({ x: direction.x, y: velocity.y, z: direction.z })
    // jumping
    const world = rapier.world.raw()
    // @ts-ignore
    const ray = world.castRay(new RAPIER.Ray(ref.current.translation(), { x: 0, y: -1, z: 0 }))
    const grounded = ray && ray.collider && Math.abs(ray.toi) <= 1.75

    // todo fix bug where jump triggers i after planting object... (can't seem to replicate)
    if ((jump && grounded) && !lockKeyboard) { // @ts-ignore
      ref.current.setLinvel({ x: 0, y: JUMP_HEIGHT, z: 0 })
    }
  })




  return (
    <>
      <RigidBody ref={ref} colliders={false} mass={1} type="dynamic" position={position} enabledRotations={[false, false, false]}>
        <CapsuleCollider args={[0.75, 0.5]} />
      </RigidBody>
      {/*{ hasObject && !searchedObject && (*/}
      {/*  <group ref={object} onPointerMissed={(e) => (handleRotation)}>*/}
      {/*    { mode === 'rose' && (*/}
      {/*      <CarriedRose position={[0.3, -0.3, 0.5]} />*/}
      {/*    )}*/}
      {/*    { mode === 'rocket' && (*/}
      {/*      <CarriedRocket position={[0.3, -0.5, 0.5]} />*/}
      {/*    )}*/}
      {/*  </group>*/}
      {/*)}*/}
    </>
  )
}
