import * as THREE from 'three'
import React, {useEffect, useRef, useState} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {ReactThreeFiber} from "@react-three/fiber";
import {InstancedBufferAttribute, InstancedMesh, Object3D} from "three";
const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/tree-one-transformed.glb`;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      // TODO UPDATE TO ALLOW NULL ON TYPES
      instancedMesh: ReactThreeFiber.Object3DNode<InstancedMesh, typeof InstancedMesh>
      instancedBufferAttribute: ReactThreeFiber.Object3DNode<InstancedBufferAttribute, typeof InstancedBufferAttribute>
    }
  }
}

type GLTFResult = GLTF & {
  nodes: {
    Tree: THREE.Mesh
  }
  materials: {
    Tree: THREE.MeshStandardMaterial
  }
}

export default function TreeCircle({ ...props }: any) {

  const NUMBER_OF_LOOPS =2
  const DIVISION_AMOUNT = 30; // 10 will produce 36 tree
  const RADIUS = 500; // 100 vs
  const [treeCount, setMountainCount] = useState(360/DIVISION_AMOUNT);

  // useEffect(() => {
  //   if(weatherData) {
  //     setMountainCount(weatherData.weather.treeCount);
  //     setSpreadDistance(weatherData.weather.spreadDistance);
  //     console.log('treeCount', weatherData.weather.treeCount)
  //     console.log('spreadDistance', weatherData.weather.spreadDistance)
  //   }
  // }, [weatherData])

  const ref = useRef(null!);
  const dummy = new Object3D();
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  useEffect(() => {
    let allTrees: any = [];

    // https://stackoverflow.com/questions/43641798/how-to-find-x-and-y-coordinates-on-a-flipped-circle-using-javascript-methods#:~:text=Typically%2C%20to%20find%20the%20x,sin(degrees%E2%80%8E%C2%B0)).
    function circleXZ(radius: number, theta: number) {
      // Convert angle to radians
      theta = (theta-90) * Math.PI/180;
      return {x: radius*Math.cos(theta),
        z: -radius*Math.sin(theta)}
    }

    for (let loop = 1; loop <= NUMBER_OF_LOOPS; loop++) {

      for (let theta = 0; theta <= 360; theta += DIVISION_AMOUNT-0.9) {
        let answer = circleXZ(RADIUS+loop*10, theta);
        // console.log('(x, y) = ' + '(' + answer.x + ', ' + answer.y + ') for theta=' + theta);
        allTrees.push({
          position: [
            answer.x + Math.random() + Math.random() + Math.random() + Math.random() + Math.random() + Math.random(),
            0,
            answer.z + Math.random() + Math.random() + Math.random() + Math.random() + Math.random() + Math.random()
          ]
        });
      }
    }

    // TODO CHECK HOW MANY THERE ACTUALLY ARE
    console.log('allTrees', allTrees);

    // pow 2 because value comes in as a sqrt
    allTrees.forEach((tree: any, index: number) => {
      // dummy.position.set(Math.random()*10*i,0, Math.random()*10*i);
      dummy.position.set(tree.position[0], tree.position[1], tree.position[2]);
      dummy.rotation.set(Math.PI / 2, 0, Math.PI / 2);
      dummy.scale.setScalar(200.5+Math.random()+Math.random());
      dummy.updateMatrix();
      // @ts-ignore
      ref.current.setMatrixAt(index, dummy.matrix);
    });
    // @ts-ignore
    ref.current.instanceMatrix.needsUpdate = true;
  }, [treeCount]);

  // Math.pow(count,2)*4], because there are 4 quadrants each squared
  // todo look into shadows
  return (
    <instancedMesh castShadow receiveShadow ref={ref} args={[nodes.Tree.geometry, materials.Tree, treeCount*NUMBER_OF_LOOPS]} >

      {/*<group ref={group} {...props} dispose={null}>*/}
      {/*  <mesh castShadow receiveShadow geometry={nodes.Mountain_B.geometry} material={materials['LPEP_Atlas01.001']} >*/}
      {/*  </mesh>*/}
      {/*</group>*/}

    </instancedMesh>
  )
}
