import {MountainLion} from "../Lion";
import {RoseRainbow, RoseSelector} from "./Rose";
import PlacedRose from "../PlacedRose";
import {SignOneSnow} from "./SnowVersion/SignOneSnow";
import {LogoSignSnow} from "./SnowVersion/LogoSignSnow";
import {Well} from "./Well";
import {Wings} from "./Wings";
import {Butterfly} from "./Butterfly";
import Dragon from "./Dragon";
import {Grass} from "../Base/Grass";
import {Ground} from "../Base/Ground";
import Mountains from "./Mountains";
import MountainOne from "./MountainOne";
import MountainTwo from "./MountainTwo";
import RockOne from "./RockOne";
import LogSnow from "./SnowVersion/LogSnow";
import StumpSnow from "./SnowVersion/StumpSnow";
import PatchSnow from "../Base/PatchSnow";
import React from "react";
import {_SpaceBase} from "../Base/_SpaceBase";
import {FieldRoses} from "./FieldRoses";
import {TreeLines} from "./TreeLines";
import TreeCircle from "./TreeCircle";
import Spider from "../Spider";

/**
 * SpaceRegular
 *
 * @param searchedRocketScale
 * @param launch
 * @param DEBUG_MODE
 * @param mode
 * @param highlightedCommunity
 * @param signArray
 * @param day
 * @param setDay
 * @param weatherData
 * @param allDone
 * @param objectData
 * @param setShowCreditsModal
 * @param searchedObject
 * @param snow
 * @param lockKeyboard
 * @param setLockKeyboard
 * @param hintMessage
 * @param setShowHintBox
 * @param setHintMessage
 * @param setSlideData
 * @param setSlideModalCharacter
 * @param setShowSlideModal
 * @param forestColliders
 * @param roseSizings
 * @param rocketSizings
 * @param setSlideMessage
 * @param setShowSizeModal
 * @param playerPosition
 * @param setPlayerPosition
 * @param setNight
 * @param night
 * @param donationAmount
 * @param objectColor
 * @param placedObject
 * @param setPlacedObject
 * @param placing
 * @param setPlacing
 * @param objectArrays
 * @param objectCount
 * @param setObjectCount
 * @param pointerControls
 * @param setShowInfoModal
 * @param setHasObject
 * @param hasObject
 * @param lowGraphics
 * @param setLowGraphics
 * @param pageNumber
 * @param setPageNumber
 * @param joystickRef
 * @constructor
 */
export function _SpaceRegular(
  {
      searchedRocketScale, launch, DEBUG_MODE, mode, highlightedCommunity, signArray, day, setDay, weatherData, allDone,
      objectData, setShowCreditsModal, searchedObject, snow, lockKeyboard, setLockKeyboard, hintMessage,
      setShowHintBox, setHintMessage, setSlideData, setSlideModalCharacter, setShowSlideModal, forestColliders,
      roseSizings, rocketSizings, setSlideMessage, setShowSizeModal, playerPosition, setPlayerPosition, setNight, night,
      donationAmount, objectColor, placedObject, setPlacedObject, placing, setPlacing,
      objectArrays, objectCount, setObjectCount, pointerControls, setShowInfoModal, setHasObject, hasObject, lowGraphics,
      setLowGraphics, pageNumber, setPageNumber, joystickRef
  }: any)
{

  return (
    <>
      <_SpaceBase joystickRef={joystickRef}
        searchedRocketScale={searchedRocketScale} launch={launch} DEBUG_MODE={DEBUG_MODE} mode={mode}
        highlightedCommunity={highlightedCommunity} signArray={signArray} day={day} setDay={setDay}
        weatherData={weatherData} allDone={allDone} objectData={objectData} setShowCreditsModal={setShowCreditsModal}
        searchedObject={searchedObject} snow={snow} lockKeyboard={lockKeyboard} setLockKeyboard={setLockKeyboard}
        hintMessage={hintMessage} setShowHintBox={setShowHintBox} setHintMessage={setHintMessage}
        setSlideData={setSlideData} setSlideModalCharacter={setSlideModalCharacter}
        setShowSlideModal={setShowSlideModal} forestColliders={forestColliders} roseSizings={roseSizings}
        rocketSizings={rocketSizings} setSlideMessage={setSlideMessage} setShowSizeModal={setShowSizeModal}
        playerPosition={playerPosition} setPlayerPosition={setPlayerPosition} setNight={setNight} night={night}
        donationAmount={donationAmount} objectColor={objectColor} placedObject={placedObject}
        setPlacedObject={setPlacedObject} placing={placing} setPlacing={setPlacing} objectCount={objectCount}
        setObjectCount={setObjectCount} pointerControls={pointerControls} setShowInfoModal={setShowInfoModal}
        setHasObject={setHasObject} hasObject={hasObject} lowGraphics={lowGraphics} setLowGraphics={setLowGraphics}
        pageNumber={pageNumber} setPageNumber={setPageNumber}
      >
          {/*<MountainLion*/}
          {/*  mode={mode} lockKeyboard={lockKeyboard} setHintMessage={setHintMessage} hintMessage={hintMessage}*/}
          {/*  setShowHintBox={setShowHintBox} playerPosition={playerPosition} setPlayerPosition={setPlayerPosition}*/}
          {/*  setHasObject={setHasObject} hasObject={hasObject} scale={0.09} position={[-3, 0.39, 3]} rotation={[0,0.9,0]}*/}
          {/*  setShowInfoModal={setShowInfoModal} pointerControls={pointerControls}*/}
          {/*/>*/}
          {/*<RoseRainbow position={[-3.3,0,3.5]} scale={2 + (0.5 * 1.5)} rotation={[-0.86 -0.5, 0.91 + 0.5, -1.2]}/>*/}

          {/*{ objectArrays && (*/}
          {/*  <FieldRoses searchedObject={searchedObject} objectArrays={objectArrays} />*/}
          {/*)}*/}

          {/*{ placedObject && (*/}
          {/*  <PlacedRose*/}
          {/*    objectColor={objectColor} placedObject={placedObject} roseSizings={roseSizings}*/}
          {/*    donationAmount={donationAmount}*/}
          {/*  />*/}
          {/*)}*/}

          {/*<SignOneSnow*/}
          {/*  lockKeyboard={lockKeyboard} setSlideMessage={setSlideMessage} setShowSlideModal={setShowSlideModal}*/}
          {/*  setSlideModalCharacter={setSlideModalCharacter} pointerControls={pointerControls}*/}
          {/*  playerPosition={playerPosition} setPlayerPosition={setPlayerPosition} position={[1,-0.5,0]} scale={0.4}*/}
          {/*  rotation={[0,0.5,0]}*/}
          {/*/>*/}
          {/*<LogoSignSnow signArray={signArray} />*/}

          {/*{ searchedObject && (*/}
          {/*  <>*/}
          {/*    <RoseSelector searchedObject={searchedObject} launch={launch} />*/}
          {/*    <Well*/}
          {/*      setShowSlideModal={setShowSlideModal} setSlideMessage={setSlideMessage}*/}
          {/*      setSlideModalCharacter={setSlideModalCharacter} position={[-15,-0.58,1.4]} scale={0.02}*/}
          {/*      rotation={[0,1.6,0]} lockKeyboard={lockKeyboard} playerPosition={playerPosition}*/}
          {/*      pointerControls={pointerControls}*/}
          {/*    />*/}
          {/*    <Wings position={[-15,-3.8,1.8]} scale={4}/>*/}
          {/*    <Butterfly*/}
          {/*      scale={0.1} launch={launch}*/}
          {/*      position={[searchedObject.position.x,searchedObject.position.y + searchedObject.roseSize.butterflyOffsetY, searchedObject.position.z]}*/}
          {/*    />*/}
          {/*  </>*/}
          {/*)}*/}

          {/*{night && !lowGraphics && (*/}
          {/*  <Dragon position={[0,10,-30]} />*/}
          {/*)}*/}

          {/*1000*1000 is the size of the ground*/}
          { !lowGraphics && (<Grass spreadDistance={3} scale={50} count={7} rotation={[0,0,0]} />)}
          <Ground />


          <Spider playerPosition={playerPosition} />

          {/* ### Terrain */}

          {/*<Mountains radius={400} divisionAmount={7} scale={10} />*/}
          {/*<MountainOne position={[310,0,-100]} scale={14}/>*/}
          {/*<MountainTwo position={[260,0,-220]} scale={13.4}/>*/}

          {/*add for high level screenshots and put on lofi*/}
          <TreeCircle/>
          {/*<Mountains radius={440} divisionAmount={9} />*/}
          {/*<Mountains radius={500} divisionAmount={11} />*/}
          {/*<Mountains radius={560} divisionAmount={14} />*/}
          {/*<Mountains radius={620} divisionAmount={19} />*/}
          {/*<Mountains radius={680} divisionAmount={25} />*/}
          {/*<Mountains radius={740} divisionAmount={32} />*/}

          {/*<RockOne position={[-40,0,10]} rotation={[0,0,0]} scale={5} />*/}
          {/*<RockOne position={[-40,0,11]} rotation={[0,1,0]} scale={1.8} />*/}
          {/*<RockOne position={[30,0,20]} rotation={[0,1,0]} scale={7} />*/}
          {/*<RockOne position={[45,0,30]} rotation={[0,3,0]} scale={6} />*/}

          {/*<TreeLines*/}
          {/*  mode={mode} forestColliders={forestColliders} lowGraphics={lowGraphics} snow={snow}*/}
          {/*  objectCount={objectCount}*/}
          {/*/>*/}
          {/*<LogSnow position={[-12,-0.5,6]} scale={2} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />*/}
          {/*<StumpSnow*/}
          {/*  lockKeyboard={lockKeyboard} position={[-8,-0.5,1]} scale={2} setShowSizeModal={setShowSizeModal}*/}
          {/*  playerPosition={playerPosition} setPlayerPosition={setPlayerPosition} pointerControls={pointerControls}*/}
          {/*/>*/}

          {/*/!*odds*!/*/}
          {/*<PatchSnow*/}
          {/*  mode={mode} lowGraphics={lowGraphics} snow={snow} position={[0,0,0]} rotation={[0,9.409,0]}*/}
          {/*  objectCount={objectCount} patch={1}*/}
          {/*/>*/}
          {/*/!*evens*!/*/}
          {/*<PatchSnow*/}
          {/*  mode={mode} lowGraphics={lowGraphics} snow={snow} position={[6,0,0]} rotation={[0,-10.995,0]}*/}
          {/*  objectCount={objectCount} patch={2}*/}
          {/*/>*/}
      </_SpaceBase>
    </>
  )
}
