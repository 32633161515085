import * as THREE from 'three'
import React, {useEffect, useRef, useState} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {ReactThreeFiber} from "@react-three/fiber";
import {InstancedBufferAttribute, InstancedMesh, Object3D} from "three";
const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/cloud1-transformed.glb`;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      // TODO UPDATE TO ALLOW NULL ON TYPES
      instancedMesh: ReactThreeFiber.Object3DNode<InstancedMesh, typeof InstancedMesh>
      instancedBufferAttribute: ReactThreeFiber.Object3DNode<InstancedBufferAttribute, typeof InstancedBufferAttribute>
    }
  }
}

type GLTFResult = GLTF & {
  nodes: {
    Icosphere002_Clouds_0: THREE.Mesh
  }
  materials: {
    Clouds: THREE.MeshStandardMaterial
  }
}

export default function Cloud1({ lowGraphics, weatherData, scale, rotation, ...props }: any) {

  const [cloudCount, setCloudCount] = useState(0);
  const [spreadDistance, setSpreadDistance] = useState(0);

  useEffect(() => {
    if(weatherData) {
      setCloudCount(weatherData.weather.cloudCount);
      setSpreadDistance(weatherData.weather.spreadDistance);
      // console.log('cloudCount', weatherData.weather.cloudCount)
      // console.log('spreadDistance', weatherData.weather.spreadDistance)
    }
  }, [weatherData])

  const ref = useRef(null!);
  const dummy = new Object3D();
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  useEffect(() => {
    // @ts-ignore
    // geometry.computeVertexNormals();

    let allClouds: any = [];

    // create cloud in quadrant 1 - back right
    for (let i = 1; i <= cloudCount; i++) {
      for (let j = 1; j <= cloudCount; j++) {
        allClouds.push({position: [i * Math.pow(Math.random() * spreadDistance, 2), j * Math.pow(Math.random() * spreadDistance,2)]});
      }
    }
    // create cloud in quadrant 2 - front left
    for (let i = 1; i <= cloudCount; i++) {
      for (let j = 1; j <= cloudCount; j++) {
        allClouds.push({position: [-i * Math.pow(Math.random() * spreadDistance, 2), -j * Math.pow(Math.random() * spreadDistance,2)]});
      }
    }
    // create cloud in quadrant 3 - back left
    for (let i = 1; i <= cloudCount; i++) {
      for (let j = 1; j <= cloudCount; j++) {
        allClouds.push({position: [-i * Math.pow(Math.random() * spreadDistance, 2), j * Math.pow(Math.random() * spreadDistance,2)]});
      }
    }
    // create cloud in quadrant 4 - front right
    for (let i = 1; i <= cloudCount; i++) {
      for (let j = 1; j <= cloudCount; j++) {
        allClouds.push({position: [i * Math.pow(Math.random() * spreadDistance, 2), -j * Math.pow(Math.random() * spreadDistance,2)]});
      }
    }

    // TODO CHECK HOW MANY THERE ACTUALLY ARE
    // console.log('this many clouds1', allClouds);

    // pow 2 because value comes in as a sqrt
    allClouds.forEach((cloud: any, index: number) => {
      // dummy.position.set(Math.random()*10*i,0, Math.random()*10*i);
      dummy.position.set(cloud.position[0], 750+(Math.random()*10*2), cloud.position[1]);
      dummy.rotation.set(0, Math.random(), 0);
      // dummy.position.set(0, 2, 0);
      // dummy.scale.setScalar(1.5+Math.random()+Math.random());
      dummy.scale.setScalar(Math.random());
      dummy.updateMatrix();
      // @ts-ignore
      ref.current.setMatrixAt(index, dummy.matrix);
    });
    // @ts-ignore
    ref.current.instanceMatrix.needsUpdate = true;
  }, [cloudCount, spreadDistance]);  // todo may need to re-add geometry (and then move geometry into variable above)

  // Math.pow(count,2)*4], because there are 4 quadrants each squared
  // todo look into shadows
  return (
    <>
      {/*{ !lowGraphics && (*/}
      {/*  <instancedMesh castShadow receiveShadow ref={ref} args={[nodes.Icosphere002_Clouds_0.geometry, materials.Clouds, Math.pow(cloudCount,2)*4]} scale={scale} rotation={rotation}>*/}
      {/*  </instancedMesh>*/}
      {/*)}*/}
      {/*{ lowGraphics && (*/}
        <instancedMesh ref={ref} args={[nodes.Icosphere002_Clouds_0.geometry, materials.Clouds, Math.pow(cloudCount,2)*4]} scale={scale} rotation={rotation}>
        </instancedMesh>
      {/*)}*/}
    </>
  )
}
